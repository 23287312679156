import { AppKeyMbti } from '@pixels/universal/model/apps/app-types';
import { ProjectMeta } from '@pixels/universal/model/apps/project-meta.model';
import { Phase } from '@pixels/universal/model/phase-model';

const FOLDER_NAME = 'mbti-chat-client';
const PROD_WEB_PUSH_KEY = 'BKpJ_8xW9jGtNyfJlKFurItnF0y6SI4heEksI0KnJAKZZ1hksbBnD7fHZdz_KL1uI24C4ggrA-m0gB9NIIA8cNg';
const DEV_WEB_PUSH_KEY = 'BD86uJ-t0mcmjn6NS1x3HSkFUprowrkqImVEdXs-bVTvxdgzwEtDvR87PSRLUMEAtpOgoF9QfEx3nwgLN9d6-Wo';
const ProdFirebaseConfig = {
  apiKey: 'AIzaSyDSbedVL9d1oGmjiKH004UD7q3tSyzRsTA',
  authDomain: 'pixels-mbti.firebaseapp.com',
  projectId: 'pixels-mbti',
  storageBucket: 'pixels-mbti.appspot.com',
  messagingSenderId: '1020231127995',
  appId: '1:1020231127995:web:8cb12ce9f110aaf196ea9e',
  measurementId: 'G-BQ5TB3NE8R'
};
const DevFirebaseConfig = {
  apiKey: 'AIzaSyCRdwiYEA_HSbmq79sayzshnTcxoK1x0C4',
  authDomain: 'pixels-mbti-dev.firebaseapp.com',
  projectId: 'pixels-mbti-dev',
  storageBucket: 'pixels-mbti-dev.appspot.com',
  messagingSenderId: '499438467780',
  appId: '1:499438467780:web:5fcb04ee3d1f0b71ed6791',
  measurementId: 'G-JRF0GJ9M4Y'
};
const PROD_TALK_PLUS_APP_ID = 'd7bb9c7b-a9f7-4fda-9761-af21d593813c';
const DEV_TALK_PLUS_APP_ID = '38352c9b-548b-4120-b064-def52cd4ead5';

export const MbtiChatProjectMeta: ProjectMeta = {
  id: AppKeyMbti,
  mainPrimaryColor: '#6EC68D',
  pushBackgroundColor: '#48E455',
  folderName: FOLDER_NAME,
  publicPath: `dist/apps/${FOLDER_NAME}/browser`,
  supportPhases: [Phase.prod, Phase.dev],
  port: 8105,
  apiName: 'mbti-api',
  apiPort: 5005,
  appId: 'io.pixelteam.mbti',
  appStoreUrl: 'https://apps.apple.com/app/id6469781282',
  appName: 'MBTI 친구 만들기',
  appNameEn: 'MBTI CHAT',
  appSchemeName: 'MBTI CHAT',
  testTargetUrl: 'https://test-mbti.pixelteam.io',
  devTargetUrl: 'https://dev-mbti.pixelteam.io',
  prodApi: 'https://mbti-api.pixelteam.io',
  devApi: 'https://dev-mbti-api.pixelteam.io',
  firebaseId: 'pixels-mbti',
  testHostname: 'test-mbti',
  clientSentryDsn: 'https://322596b84a09d7285a02b10be1eff0b3@o190729.ingest.us.sentry.io/4506061801390080',
  prod: {
    deployPath: '/api/v1/project/9070/stage/10103/scenario/11300/deploy',
    targets: ['prod-02', 'prod-03'],
    port: 9000,
    androidAppId: '1:1020231127995:android:94100aaf96182b9f96ea9e',
    iosAppId: '1:1020231127995:ios:ade20b916ab5203d96ea9e',
    iosEncodingAppId: 'app-1-1020231127995-ios-ade20b916ab5203d96ea9e',
    webAppId: '1:1020231127995:web:8cb12ce9f110aaf196ea9e',
    googleReversedId: 'com.googleusercontent.apps.1020231127995-71tlv0faehjhdlf837ebme8k16pumgu3',
    kakaoAppKey: 'b2650536e0b539670712e8c2191a183e',
    webPushKey: PROD_WEB_PUSH_KEY,
    firebaseConfig: ProdFirebaseConfig,
    talkPlusAppId: PROD_TALK_PLUS_APP_ID,
    apiUrl: 'https://mbti-api.pixelteam.io',
  },
  dev: {
    deployPath: '/api/v1/project/9070/stage/10104/scenario/11287/deploy',
    targets: ['dev-00'],
    port: 9001,
    androidAppId: '1:499438467780:android:9f26f9df57f67741ed6791',
    iosAppId: '1:499438467780:ios:2228668f8a8f73dfed6791',
    iosEncodingAppId: 'app-1-499438467780-ios-2228668f8a8f73dfed6791',
    webAppId: '1:499438467780:web:5fcb04ee3d1f0b71ed6791',
    googleReversedId: 'com.googleusercontent.apps.499438467780-3fv99828tj2ngshkm8iqcpgd50ss42gh',
    kakaoAppKey: '11cc2d3d263f1f789de6d4393e281fd6',
    webPushKey: DEV_WEB_PUSH_KEY,
    firebaseConfig: DevFirebaseConfig,
    talkPlusAppId: DEV_TALK_PLUS_APP_ID,
    apiUrl: 'https://dev-mbti-api.pixelteam.io',
  },
  test: {
    androidAppId: '1:499438467780:android:10d78e2aee890eaeed6791',
    iosAppId: '1:499438467780:ios:2c70c6ae4ff52819ed6791',
    iosEncodingAppId: 'app-1-499438467780-ios-2c70c6ae4ff52819ed6791',
    googleReversedId: 'com.googleusercontent.apps.499438467780-evj3nb7p3n81d1f0pmqibe6ktaahvkai',
    kakaoAppKey: '11cc2d3d263f1f789de6d4393e281fd6',
    webPushKey: DEV_WEB_PUSH_KEY,
    firebaseConfig: DevFirebaseConfig,
    talkPlusAppId: DEV_TALK_PLUS_APP_ID,
    apiUrl: 'https://test-mbti-api.pixelteam.io',
  },
  android: {
    admobAppId: 'ca-app-pub-4000507444081320~8327024418',
    admobBannerVideoChatAppId: 'ca-app-pub-4000507444081320/1448472442',
  },
  ios: {
    admobAppId: 'ca-app-pub-4000507444081320~5700861074',
    admobBannerVideoChatAppId: 'ca-app-pub-4000507444081320/9068576625',
  },
  NSCameraUsageDescription: '카메라로 찍은 사진을 메시지에 첨부 또는 프로필 사진으로 등록할 수 있습니다.',
  NSPhotoLibraryAddUsageDescription: '회원님이 MBTI 친구 만들기에 사진을 저장에 대한 액세스 권한을 사용합니다.',
  NSPhotoLibraryUsageDescription: '앨범에 있는 사진과 GIF를 메시지에 첨부 또는 프로필 사진으로 등록할 수 있습니다.',
  NSCameraUsageDescriptionEn: 'You can attach a photo taken with your camera to a message or list it as your profile picture.',
  NSPhotoLibraryAddUsageDescriptionEn: 'You use access to save photos to make MBTI friends.',
  NSPhotoLibraryUsageDescriptionEn: 'You can use photos and GIFs from your albums as attachments to messages or as your profile picture.',
  consoleLogo: `
███╗   ███╗██████╗ ████████╗██╗     ██████╗██╗  ██╗ █████╗ ████████╗
████╗ ████║██╔══██╗╚══██╔══╝██║    ██╔════╝██║  ██║██╔══██╗╚══██╔══╝
██╔████╔██║██████╔╝   ██║   ██║    ██║     ███████║███████║   ██║   
██║╚██╔╝██║██╔══██╗   ██║   ██║    ██║     ██╔══██║██╔══██║   ██║   
██║ ╚═╝ ██║██████╔╝   ██║   ██║    ╚██████╗██║  ██║██║  ██║   ██║   
╚═╝     ╚═╝╚═════╝    ╚═╝   ╚═╝     ╚═════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝
`
};

