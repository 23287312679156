import { inject, Injectable, signal } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { Platform } from '@ionic/angular/standalone';
import { ONE_ON_ONE_ADMIN_MENUS } from '@pixels/client/pages/admin/admin.model';
import { AppInfo } from '@pixels/client/pixels/app-info';
import { AppKeyMbti } from '@pixels/universal/model/apps/app-types';
import { MbtiChatProjectMeta } from '@pixels/universal/model/apps/project-meta/mbti-chat-project-meta';

@Injectable({ providedIn: 'root' })
export class MbtiAppInfo extends AppInfo {
  public readonly appType = AppKeyMbti;
  public readonly serviceName = _('@MBTI_친구_만들기@');
  public readonly appStoreUrl = signal(MbtiChatProjectMeta.appStoreUrl ?? '');
  public readonly playStoreUrl = signal(`https://play.google.com/store/apps/details?id=${MbtiChatProjectMeta.appId}`);

  public override readonly admobBannerVideoChatAppId = (inject(Platform).is('ios') ? MbtiChatProjectMeta.ios?.admobBannerVideoChatAppId : MbtiChatProjectMeta.android?.admobBannerVideoChatAppId) ?? '';
  public override readonly loginTitle = _('@MBTI_친구찾기@');
  public override readonly loginDescription = _('@지역별_또래별_n_MBTI로_친구찾기@');
  public override readonly loginAppIconUrl = 'https://cdn.pixelteam.io/pixels/app-icons/mbti-app-icon.svg';
  public override readonly bannerPlaceHolder = _('@MBTI_친구_만들기_채팅_수다_친목@');
  public override readonly adminMenus = ONE_ON_ONE_ADMIN_MENUS;
}
